<template>
  <div class="material">
    <div v-for="item in list" :key="item.id">
      <div class="group">
        <div class="head">
          <div class="tt">{{ item.name }}</div>
        </div>
        <div v-if="item.remarks" class="notice">
          {{ item.remarks }}
        </div>
        <div class="cont">
          <van-uploader v-model="item.imgList" :deletable="false" :max-count="item.imgList.length" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMaterialImgByOrder } from '@/api/product'
import { getOssUrl } from '@/utils/oss'
export default {
  data() {
    return {
      fileList: [],
      orderId: '',
      list: []
    }
  },
  mounted() {
    this.orderId = this.$route.query.orderId
    this.getMaterialImgByOrder()
  },
  methods: {
    async getMaterialImgByOrder() {
      const res = await getMaterialImgByOrder({ orderId: this.orderId })
      const list = res.datas
      for (let i = 0; i < list.length; i++) {
        for (let j = 0; j < list[i].imgList.length; j++) {
          list[i].imgList[j].url = await getOssUrl(list[i].imgList[j].imgName)
        }
      }
      this.list = list
    }
  }
}
</script>
  <style lang="scss" scoped>
  .material {
    padding: 16px;
    .group {
      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
       .tt {
          font-size: 14px;
          color: #333333;
        }
       .num {
          font-size: 14px;
          color: #CCCCCC;
        }
      }
    }
    .notice {
      font-size: 12px;
      color: #999999;
      padding: 0 0 10px 0;
    }
  }
  </style>

